<template>
  <div class="px-5">
    <title-form
      title="Añadir título"
      ref="titleForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import TitleForm from "./Form.vue"
export default {
  components: {
    TitleForm
  },
  data() {
    return {
      loading: false,
      form: {},
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.titleForm.form

      let route = ''
      let payload = {}

      if (form.type == 1) {
        route = '/academy_title'

        payload = {
          id_academy: form.id_academy,
          id_title: form.id_title,
          year: form.year,
          observation: form.observation
        }
      }
      if (form.type == 2) {
        route = '/coach_title'

        payload = {
          id_coach: form.id_coach,
          id_title: form.id_title,
          year: form.year,
          observation: form.observation
        }
      }
      if (form.type == 3) {
        route = '/player_title'

        payload = {
          id_player: form.id_player,
          id_title: form.id_title,
          year: form.year,
          observation: form.observation
        }
      }

      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: payload,
          route: route + "/save",
          module: "title"
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            notify().then(({ notification }) => {
              notification("master", response.code, this.$snotify)
            })
            this.loading = false
            this.$router.push("/academias/"+this.$route.params.id+"/categorias/"+this.$route.params.id2+"/titulos")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
  },
  created() {}
}
</script>
